import React from "react";
import Layout from "../components/layout/ca"
import SEO from "../components/seo"
import HeroImage from "../components/ui-sections/hero-image"
import NewsGrid from "../components/news/news-grid"

const NoticiesPage = (props) => {
  const heroText = "Notícies"

  return (
    <Layout location= {props.location}>
      <SEO 
        title="Notícies"
        lang="ca"
      />
      <HeroImage 
        titleText={heroText}
        image="noticies" 
      />
      <NewsGrid numberPreviews="-1" />
    </Layout>
  )
}

export default NoticiesPage
